import gsap from "gsap";
import Splide from '@splidejs/splide';
import { observer } from "./shared";

const
section_1 = document.getElementById("section-1"),
section_2 = document.getElementById("section-2"),
section_3 = document.getElementById("section-3"),
section_4 = document.getElementById("section-4"),
fields = document.querySelectorAll(".field"),
carousel = document.getElementById("carousel"),
option = {
    type: 'loop',
    start:0,
    cover: true,
    mediaQuery: 'min',
    fixedWidth : '95vw',
    heightRatio: 1,      
    breakpoints:{
        900:{
            fixedHeight : '450px',
            fixedWidth : '450px',        
            cover: false
        }
    },
    lazyLoad: true,
    arrows: true,
    pagination: true,
    drag: true,
    arrowPath: 'M39.16,19.17c1.12-1.12,1.12-2.93,0-4.04L24.88,.84c-1.12-1.12-2.93-1.12-4.04,0s-1.12,2.93,0,4.04l9.42,9.41H2.86c-1.58,0-2.86,1.28-2.86,2.86s1.28,2.86,2.86,2.86H30.24l-9.4,9.41c-1.12,1.12-1.12,2.93,0,4.04s2.93,1.12,4.04,0l14.29-14.29h0Z'
};

fields.forEach(f => {
    f.addEventListener("click", function() {
        const state = this.classList.contains("active");
        const id = this.id.replace("field","image");
        const image = document.getElementById(id);
        reset();
        if(state){
            close_field(this, image);
        }else{
            open_field(this, image);
        }
    })
})

const reset = () => {
    const field = document.querySelector(".field.active");
    if(!!field){
        const id = field.id.replace("field","image");
        const image = document.getElementById(id);
        close_field(field, image);
    }

};

const open_field = (field, image) => {
    gsap.to(image,{autoAlpha: 1});
    field.classList.add("active");
};

const close_field = (field, image) => {
    gsap.to(image,{autoAlpha: 0});
    field.classList.remove("active");
};

const main = new Splide(carousel, option).mount();

observer.observe(section_1);
observer.observe(section_2);
observer.observe(section_3);
observer.observe(section_4);
