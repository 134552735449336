import gsap from "gsap";

const
body = document.querySelector("body"),
header = document.querySelector("header"),
burger = document.getElementById("burger"),
menu = document.getElementById("menu");

let 
isMobile = window.innerWidth<900,
menu_state = false,
lastScrollTop = window.scrollY,
offset = 200;

window.addEventListener("resize", () => {
    isMobile = window.innerWidth<900;
});

//______MENU______//

const open_menu = ()=>{
    body.style.overflow = "clip";
    gsap.timeline()
    .to(["#line_1", "#line_3"],{y:10.256},"0")
    .to(["#line_1", "#line_2", "#line_3"],{autoAlpha:0},"0")
    .to(["#line_4", "#line_5"],{autoAlpha:1, delay:0.3},"0")
    gsap.timeline()
    .fromTo(menu,{y:"100%", autoAlpha:0, duration:1, ease:"expo.out"},{y: 0, autoAlpha:1, duration:1, ease:"expo.out"})
};

const close_menu = () =>{
    body.style.overflow = "inherit";
    gsap.timeline()
    .to(["#line_4", "#line_5"],{autoAlpha:0},"0")
    .to(["#line_1", "#line_2", "#line_3"],{autoAlpha:1},"0")
    .to(["#line_1"],{y:0},"0")
    .to(["#line_3"],{y:20.512},"0")
    .to(menu,{y:"-120%", autoAlpha:0},"0")
};

burger.addEventListener("click",()=>{
    if(menu_state){
        close_menu();
    }else{
        open_menu();
    }
    menu_state = !menu_state;
});

window.addEventListener('scroll',()=>{
    const scrollTopPosition = window.scrollY;
    if(menu_state) return;
    if(scrollTopPosition == 0){ //Menu appear at top
        header.classList.remove("scrollView");
        gsap.to(header,{
            y:0,
            delay:0.5
        });
          return;
    }
    if (scrollTopPosition > lastScrollTop && scrollTopPosition > offset) { //Menu dissapear on scroll down
      gsap.to(header,{
        y:isMobile? -90 : -180,
      })
    } else if (scrollTopPosition>offset && scrollTopPosition < lastScrollTop) { //Menu appear on scroll up
      header.classList.add("scrollView");
      gsap.to(header,{
        y:0,
      })
    }
    lastScrollTop = scrollTopPosition <= 0 ? 0 : scrollTopPosition;
  },
  false,
);

//______XX______//

export {isMobile};

