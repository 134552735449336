import gsap from "gsap";
import { isMobile } from ".";

const 
options = {
    root: null,
    rootMargin: isMobile? '0px':'300px',
    threshold: isMobile? 0.15 : 0.75
};

export const observer = new IntersectionObserver(function(entries, observer) {
  entries.forEach(entry => {
    if(entry.isIntersecting) {
        let images = entry.target.querySelectorAll("img");
        
        images = images.length === 0 ? entry.target.querySelector(".image svg") : images;
        const text = entry.target.querySelectorAll(".text");
        if(entry.target.classList.contains("reverse")){
          gsap.fromTo(text,{autoAlpha: 0, x: -150},{autoAlpha: 1, x: 0});
          gsap.to(images,{"--width" : "100%"});
        }else{
          gsap.fromTo(text,{autoAlpha: 0, x: 150},{autoAlpha: 1, x: 0});
          gsap.to(images,{"--width" : "100%"});
      }
      observer.unobserve(entry.target);
    }
  });
}, options);
